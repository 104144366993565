/**
 * @format
 * @Author: huweijian
 * @Date: 2020-04-16 16:08:30
 * @Desc: 路由
 */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {getStorage} from '@utils/localStorage'
import {Router, Switch, Route,Redirect} from 'react-router-dom'
// eslint-disable-next-line no-unused-vars
import { MenuItemT } from './tools'

import {loginOutDo} from '@utils/base'

import loadable from './loadable'
import history from './history'
// import {
//   updateUserPermissions,
//   updateUserInfo,
//   updateCompanyInfo,
//   updateLocalRouter,
//   updateFinanceInfo
// } from './createDynamicRouter'

import {getToken} from '@utils/cookieStorage'
import {setStorage} from '@utils/localStorage'

import {Modal} from 'antd'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import Help from '@view/help/Help'
import NoMatch from '@src/view/errorPage/404'

// 业务模块路由引入
// import Home from '@view/homeIndex/HomeIndex' // 首页
import {h5RoutesList} from './routers/commonRouter'



// 逻辑代码
export interface routeT {
  path: string
  // component: React.ReactNode
  component?: React.ComponentType // 页面组件
  title?: string
  redirect?: string

  // 拥有这里面的任意一个权限即可访问该路由，这个为空，或者无，表示这个路由无需权限即可访问。
  // 这里面具体填什么值，要看这个页面需要调哪些接口，那些接口分别需要什么权限（问后端），把这些需要的权限全部填进来
  permissions?: Set<string>

  isMenu?: boolean // 是否出现在菜单中
  noNeedLayout?: boolean // 该页面是否需要左侧菜单栏和顶部信息栏
  menuIcon?: string | React.ComponentType // 菜单icon。如果是字符串，要先到 @view/layoutPage/LayoutPageMainMenu.MenuIconMap 中，加上你需要的icon
  // 更多子路由
  // ps. 如果有`/a/b/c`和`/a/b/c/d`这两种路由同时存在，后者在数组中要放在前者的前面，否则 react-router-dom 会认为`/a/b/c`就已经匹配url了
  routes?: routeT[]
}
export type routesT = routeT[]

// const imp = (path: string) => import(`../view/${path}`)
export const routes: routesT = [
  {
    path: '/help',
    component: loadable(() => import('@view/help/Help')),
    title: '帮助'
  },
  {
    path: '/404',
    component: loadable(() => import('@src/view/errorPage/404')),
    title: '404'
  },
]

export interface RouteType extends routeT {
  location?: any
}

// export interface RouteType  {
//   routes: RouteProps[]
// }

/**
 * 路由渲染
 * @param route 路由内容
 */
const RouteWithSubRoutes: React.FC<RouteType> = route => {
  const token = getToken()
  if (!token && route.path !== '/home') {
    history.push({
      pathname: '/home'
    })
    setStorage('exit', 1)
  }
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} {...route} />
      )}
    />
  )
}
export interface SubRouterT {
  routes: RouteType[]
  location?: any
}
/**
 * 嵌套路由
 * @param routes 嵌套路由内容
 */
export const SubRouter: React.FC<SubRouterT> = ({routes}) => {
  // console.log('routes', routes)
  return (
    <>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
        {/* <Redirect path="*" to={route.redirect} /> */}
        {/* <Route component={LoadingMatch} /> */}
      </Switch>
    </>
  )
}

// 跳转顶部
export interface ScrollToTopRouterT {
  routes: RouteType[]
  location?: any
}

/**
 * 路由渲染主模块
 */
let timmer: any
export const RouterComponent: React.FC = () => {
  const sys = useSelector((state: any) => state.sys)
  const menu: MenuItemT[] = sys.menu

  // useEffect(() => {
  //   updateLocalRouter(sys.permissions, menu, routes)
  // }, [sys])

  useEffect(() => {
    window.scrollTo(0, 0)

    // FIXME: 移除了登录页提示请登录的警告
    // if (!window.location.pathname.includes('login')) {
    //   updateUserPermissions(sys.permissions).finally()
    //   updateCompanyInfo().finally()
    //   updateUserInfo().finally()
    //   updateFinanceInfo().finally()
    // }
  }, [])

  // 设置定时，检测登出状态
  const setTimer = () => {
    timmer = setInterval(() => {
      const status = getStorage('exit')
      if (window.location.pathname.includes('login')) {
        window.clearInterval(timmer)
      } else if (parseInt(status) === 1) {
        showLoginOut()
        window.clearInterval(timmer)
      }
    }, 3000)
  }

  const showLoginOut = () => {
    Modal.info({
      title: '您正在其他设备登录或已退出，请刷新后操作！',
      icon: <ExclamationCircleOutlined />,
      okText: '我知道了',
      onOk() {
        loginOutDo()
      }
    })
  }

  useEffect(() => {
    // setTimer()
  }, [])

  // H5业务路由
  const DomH5RoutesModel = h5RoutesList.map((item, index) => (
    <Route key={'h5_route_'+index} path={item.path} exact component={item.component} />
  ))

  return (
    <Router history={history}>
      <>
        <Switch>
          {/* 设置 / 默认页面  */}
          {/* <Route path="/login" exact component={Login} /> */}
          {DomH5RoutesModel}
          {/* {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))} */}
          <Route path="/help" exact component={Help} />
          {/* 设置无匹配时 404页面 */}
          <Redirect path="/" to="/home" />
          {/* <Redirect path="*" to="/404" /> */}
          <Route component={NoMatch} />
        </Switch>
      </>
    </Router>
  )
}
