/**
 * @format
 * @Author: Firmiana
 * @Date: 2020-04-23 18:10:34
 * @Desc: cookies
 */

import Cookies from 'js-cookie'
// eslint-disable-next-line no-unused-vars

const TokenKey = 'dish-i-token'

export function getToken() {
  return Cookies.get(TokenKey)
}
export function setToken(token: string, timeStr?: number | string) {
  return Cookies.set(TokenKey, token, {
    expires: new Date(new Date().getTime() + +timeStr * 1000),
  })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getCookie(name: string) {
  return Cookies.get(name)
}

export function setMoreCookie(params: any, options = {}) {
  for (const key in params) {
    Cookies.set(key, params[key], options)
  }
}
