/**
 * @format
 * @Author: huweijian
 * @Date: 2020-05-06 12:05:24
 * @Desc: loadable组件封装
 */
import React from 'react'
// eslint-disable-next-line no-unused-vars
import loadable, {DefaultComponent} from '@loadable/component'
// import {Spin} from 'antd'

//通用的过场组件
const LoadingComponent = () => {
  return (
    <div
      style={{
        textAlign: 'center'
      }}>
      {/* <Spin /> */}
    </div>
  )
}

//过场组件默认采用通用的，若传入了loading，则采用传入的过场组件
export default (
  loader: (props: unknown) => Promise<DefaultComponent<unknown>>,
  Loading = LoadingComponent
) => {
  return loadable(loader, {
    fallback: <Loading />
  })
}
