/**
 * @format
 * @Author: huweijian
 * @Date: 2020-02-08 09:18:32
 * @Desc: 开发时入口文件
 */

import React from 'react'
import ReactDOM from 'react-dom'
import {ConfigProvider} from 'antd'
import {Provider} from 'react-redux'
import zhCN from 'antd/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import store from '@store/index'
import 'lib-flexible'
import {RouterComponent} from './router'
import './App.scss'
import '@assets/scss/styles.scss'
import 'antd-mobile/dist/antd-mobile.css';
import "react-pullload/dist/ReactPullLoad.css";

moment.locale('zh-cn')
ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <RouterComponent />
    </Provider>
  </ConfigProvider>,
  document.getElementById('app')
)
