/**
 * @format
 * @Author: Firmiana
 * @Date: 2020-04-23 18:10:34
 * @Desc: localStorage 改 sessionStorage
 */
const localStorage = window.sessionStorage

/**
 * 保存数据
 * @export
 * @param {*} name
 * @param {*} val
 * @returns
 */
export function setStorage(name: string, val: string | object | any) {
  return localStorage.setItem(
    name,
    typeof val === 'object' ? JSON.stringify(val) : val
  )
}
/**
 * 获取缓存数据
 * @export
 * @param {*} name
 * @returns
 */
export function getStorage(name: string) {
  return name ? localStorage.getItem(name) : ''
}

/**
 * 删除缓存数据
 * @export
 * @param {*} name
 */
export function removeStorage(name: string) {
  return localStorage.removeItem(name)
}

/**
 * 清空全部缓存数据
 * @export
 */
export function clearStorage() {
  return localStorage.clear()
}
