/**
 * @Author: Firmiana
 * @Date: 2021-08-01 16:43:58
 * @Desc: h5页面路由统一配置
 */

import loadable from '../loadable'

export interface routeT {
  path: string
  // component: React.ReactNode
  component?: React.ComponentType // 页面组件
  title?: string
  redirect?: string

  // 拥有这里面的任意一个权限即可访问该路由，这个为空，或者无，表示这个路由无需权限即可访问。
  // 这里面具体填什么值，要看这个页面需要调哪些接口，那些接口分别需要什么权限（问后端），把这些需要的权限全部填进来
  permissions?: Set<string>

  isMenu?: boolean // 是否出现在菜单中
  noNeedLayout?: boolean // 该页面是否需要左侧菜单栏和顶部信息栏
  menuIcon?: string | React.ComponentType // 菜单icon。如果是字符串，要先到 @view/layoutPage/LayoutPageMainMenu.MenuIconMap 中，加上你需要的icon
  // 更多子路由
  // ps. 如果有`/a/b/c`和`/a/b/c/d`这两种路由同时存在，后者在数组中要放在前者的前面，否则 react-router-dom 会认为`/a/b/c`就已经匹配url了
  routes?: routeT[]
}
export type routesT = routeT[]

export const h5RoutesList: routesT = [
  {
    path: '/home',
    component: loadable(() => import('@view/homePage/HomePage')),
    title: '主页',
  },
  {
    path: '/home/detail',
    component: loadable(() => import('@view/homePage/HomeDetail')),
    title: '详情',
  },
  {
    path: '/home/watch',
    component: loadable(() => import('@view/homePage/HomeDetailWatch')),
    title: '鸟瞰图',
  },
]
