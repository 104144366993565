/**
 * @format
 * @Author: huweijian
 * @Date: 2020-04-20 18:02:49
 * @Desc: 基础类工具方法
 */

// eslint-disable-next-line no-unused-vars
import { Location } from 'history'
// eslint-disable-next-line no-unused-vars
import { objString } from '@customTypes/index'
import history from '@router/history'
import { removeToken } from './cookieStorage'
import { removeStorage } from './localStorage'

/**
 * 获取实时随机字符串
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536 + '', 10) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * 解析search参数
 * @param location
 */
export const getSearchObj = (location: Location) => {
  const { search } = location
  const obj: objString = {}
  search
    .replace(/\?/, '')
    .split('&')
    .forEach((item: string) => {
      const a = item.split('=')
      obj[a[0]] = a[1]
    })
  return obj
}

/**
 * 等待t毫秒
 * @param t 毫秒数 默认 500
 * @returns {Promise<any>}
 */
export function wait(t = 500): Promise<any> {
  return new Promise((resolve) => {
    let timer = setTimeout(() => {
      timer = null
      clearTimeout(timer)
      resolve()
    }, t)
  })
}

/**
 *  判断是否为空对象
 * @param obj
 */
export function emptyObj(obj: {}) {
  for (let key in obj) {
    return false
  }
  return true
}

/**
 * 验证身份证号码合法性
 * @param idCard
 * @returns {boolean}
 */
export function checkIdCard(idCard: string): boolean {
  if (idCard.length !== 18) {
    return false
  }
  const list = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
  const lastList = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2]
  let sum = 0
  for (let i = 0; i < list.length; i++) {
    if (+idCard[i] >= 0 && +idCard[i] <= 9) {
      sum += +idCard[i] * list[i]
    } else {
      return false
    }
  }
  sum %= 11
  if (lastList[sum] === 10) {
    if (idCard[17].toLowerCase() === 'x') {
      return true
    }
  } else {
    if (lastList[sum] === +idCard[17]) {
      return true
    }
  }

  return false
}

/**
 * 退出登录，或者登录失效要做的事
 * @param storage
 */
export function loginOutDo(storage = ['menu', 'tabs', 'token']) {
  removeToken()
  storage.forEach((item) => removeStorage(item))
  // history.push('/login')
  history.push('/home')
}

/**
 * 判断一个变量是否非法
 * 以下均为非法
 * ''
 * null
 * undefined
 * NaN
 * Infinity
 *
 * ps. 数据为字符串、对象、数组时，isNaN 均返回true
 * @author hhh
 * */
export function illegal(o: any) {
  return (
    o === '' ||
    o === null ||
    o === undefined ||
    (typeof o === 'number' && isNaN(o)) ||
    o === Infinity
  )
}

/**
 * 判断一个值是否为空
 * 包含上面的所有，以及以下
 * []
 * {}
 * ''
 * 0
 * false
 * @author hhh
 * */
export function empty(o: any) {
  return !o || o.length === 0 || Object.keys(o).length === 0 || illegal(o)
}

/**
 * 清除对象里值被判断为非法的key
 * @author hhh
 * @param obj obj 如果想支持array再改呗
 * @param need array 需要的key
 * @return 返回新obj
 * */
export function clearIllegalOfObj<T extends { [k: string]: any }>(
  obj: T,
  need?: Array<string>
): T {
  if (!obj) {
    return obj
  }
  const newObj: { [k: string]: any } = {}
  for (let k of Object.keys(obj)) {
    if (!illegal(obj[k]) && (!need || need.includes(k))) {
      newObj[k] = obj[k]
    }
  }
  return newObj as T
}

/**
 * 转对象为字符串参数
 * @param o {object} 要处理的对象
 * @returns {string}
 */
export function paramToStr(o: { [key: string]: string }) {
  let str = ''
  for (const k in o) {
    str += `${k}=${o[k]}&`
  }
  return str ? `?${str.replace(/&$/, '')}` : str
}

/**
 * 文件大小
 * @param size {number} 大小byte
 * @returns {string}
 */
export function formatSize(size: number) {
  let i
  const unit = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
  for (i = 0; i < unit.length && size >= 1024; i++) {
    size /= 1024
  }
  const s = Math.round(size * 100) / 100 || 0
  return s + unit[i]
}

// 上传校验
export function randomString(len: any) {
  len = len || 32
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_'
  const maxPos = chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars[Math.floor(Math.random() * maxPos)]
  }
  return pwd
}
