/**
 * @format
 * @Author: Firmiana
 * @Date: 2020-04-21 14:26:31
 * @Desc: 404
 */
import React from 'react'
import errorImg from '@assets/image/common/404.png'
import history from '@router/history'
import './ErrorPage.scss'

const NoMatch: React.FC = () => {
  return (
    <div className="error-index-404">
      <div className="err-main">
        <img className="err-img" src={errorImg} />
        <p className="err-p">抱歉你访问的页面不存在</p>
        <a
          className="err-btn"
          onClick={() => {
            history.push({
              pathname: '/home'
            })
          }}>
          返回首页
        </a>
        <a
          className="err-span"
          onClick={() => {
            history.go(-1)
          }}>
          或者，返回上一页
        </a>
      </div>
    </div>
  )
}

export default NoMatch
